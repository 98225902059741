<template>
  <v-container fluid>
    <v-card flat color="transparent" class="pa-sm-4 wraperx">
      <v-card-title class="primary--text">
        View Sub Category
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
            <v-img
              v-else
              :src="item.image"
              max-width="100%"
              contain
              class="text-right"
            >
              <v-chip
                dark
                class="ma-2"
                :color="item.is_enabled ? 'blue' : 'red'"
                small
              >
                {{ item.is_enabled ? "Enabled" : "Disabled" }}
              </v-chip>
            </v-img>
          </v-col>
          <v-col cols="12" md="6">
            <v-sheet class="mb-4" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Sub Category name</v-sheet
              >
              <v-skeleton-loader
                type="heading"
                v-if="loading"
              ></v-skeleton-loader>
              <h2 class="text--primary large font-weight-medium" v-else>
                {{ item.name }}
              </h2>
            </v-sheet>
            <v-sheet class="mb-4" max-width="480" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Description</v-sheet
              >
              <v-skeleton-loader
                type="paragraph"
                v-if="loading"
              ></v-skeleton-loader>
              <p class="text--primary" v-else>
                {{ item.description }}
              </p>
            </v-sheet>
            <v-sheet class="d-flex flex-wrap" color="transparent">
              <v-card width="200" class="ma-2 flex-grow-1" outlined>
                <v-list-item three-line class="ps-0 pe-1">
                  <v-list-item-avatar tile size="90" color="grey" class="my-0">
                    <v-img :src="item.image"> </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="pb-0">
                    <v-skeleton-loader
                      type="paragraph"
                      v-if="loading"
                    ></v-skeleton-loader>
                    <v-list-item-subtitle v-else class="pe-2">
                      This Sub Category has
                      {{ item.total_sub_sub_category_Count }} Sub Sub Categories
                    </v-list-item-subtitle>

                    <v-list-item-action-text class="text-right" v-if="!loading">
                      <v-btn
                        text
                        small
                        color="primary"
                        @click="viewSubSubCategories"
                      >
                        View all
                      </v-btn>
                    </v-list-item-action-text>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <v-card width="200" class="ma-2 flex-grow-1" outlined>
                <v-list-item three-line class="ps-0 pe-1">
                  <v-list-item-avatar tile size="90" color="grey" class="my-0">
                    <v-img :src="item.image"> </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="pb-0">
                    <v-skeleton-loader
                      type="paragraph"
                      v-if="loading"
                    ></v-skeleton-loader>
                    <v-list-item-subtitle v-else class="pe-2">
                      This Sub Category has
                      {{ item.total_items_count }} Items
                    </v-list-item-subtitle>

                    <v-list-item-action-text class="text-right" v-if="!loading">
                      <v-btn text small color="primary" @click="viewItems">
                        View all
                      </v-btn>
                    </v-list-item-action-text>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
        <v-card outlined class="mt-6 text--secondary" v-if="!loading">
          <v-card-title class="pb-0">
            Actions
            <v-spacer></v-spacer>
          </v-card-title>
          <v-sheet class="d-flex align-center mx-4 my-2 text--secondary">
            Do you want to edit this sub category? Make changes to its name,
            description, or settings.
            <v-divider class="ms-2"></v-divider>
            <v-btn color="primary" text depressed @click="editItem()">
              <v-icon left> mdi-pencil </v-icon> Edit
            </v-btn>
          </v-sheet>
          <v-sheet
            class="d-flex align-center mx-4 my-2 text--secondary"
            v-if="item.is_enabled"
          >
            Do you want to disable this sub category? It will be hidden from
            users but can be re-enabled later.
            <v-divider class="ms-2"></v-divider>
            <v-btn color="orange" text depressed @click="disableDialog = true">
              <v-icon left> mdi-close </v-icon> disable
            </v-btn>
          </v-sheet>
          <v-sheet class="d-flex align-center mx-4 my-2 text--secondary" v-else>
            Do you want to enable this sub category? It will be visible and
            accessible to users.
            <v-divider class="ms-2"></v-divider>
            <v-btn color="blue" depressed text @click="enableDialog = true">
              <v-icon left> mdi-check </v-icon> enable
            </v-btn>
          </v-sheet>
          <v-sheet class="d-flex align-center mx-4 my-2 text--secondary">
            Are you sure you want to delete this sub category? This action is
            irreversible.
            <v-divider class="ms-2"></v-divider>
            <v-btn color="error" depressed text @click="deleteDialog = true">
              <v-icon left> mdi-trash-can-outline </v-icon> delete
            </v-btn>
          </v-sheet>
        </v-card>
      </v-card-text>
    </v-card>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this sub category? This action cannot
          be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteSubCategoryX()"
            :loading="deleteLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="enableDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to enable this sub category?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            text
            @click="enableSubCategoryX()"
            :loading="enableLoading"
          >
            Enable
          </v-btn>
          <v-btn color="primary" text @click="enableDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="disableDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to disable this sub category?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            text
            @click="disableSubCategoryX()"
            :loading="disableLoading"
          >
            Disable
          </v-btn>
          <v-btn color="primary" text @click="disableDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import {
  getSubCategory,
  deleteSubCategory,
  disableSubCategory,
  enableSubCategory,
} from "@/services/subCategoriesService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    item: {},
    deleteLoading: false,
    deleteDialog: false,
    enableLoading: false,
    enableDialog: false,
    disableLoading: false,
    disableDialog: false,
    categoryId: null,
  }),
  async created() {
    if (this.$route.query.cid) {
      this.categoryId = Number(this.$route.query.cid);
      this.getSubCategoryX();
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "PageSubCategoryList",
        query: { cid: this.item.category_id },
      });
    },
    editItem() {
      this.$router.push({
        name: "PageEditSubCategory",
        params: { id: this.item.id },
        query: { bc: this.item.name, cid: this.item.category_id },
      });
    },
    async getSubCategoryX() {
      this.loading = true;
      let getSubCategoryReturn = await getSubCategory(
        this.categoryId,
        this.$route.params.id
      );
      this.item = getSubCategoryReturn;
      this.loading = false;
    },
    async deleteSubCategoryX() {
      let data = {
        id: this.item.id,
      };
      this.deleteLoading = true;
      let deleteSubCategoryReturn = await deleteSubCategory(
        this.categoryId,
        data
      );
      this.deleteLoading = false;
      if (deleteSubCategoryReturn == "success") {
        this.deleteDialog = false;
        this.goBack();
      }
    },
    async enableSubCategoryX() {
      let data = {
        id: this.item.id,
      };
      this.enableLoading = true;
      let enableSubCategoryReturn = await enableSubCategory(
        this.categoryId,
        data
      );
      this.enableLoading = false;
      if (enableSubCategoryReturn == "success") {
        this.enableDialog = false;
        this.getSubCategoryX();
      }
    },
    async disableSubCategoryX() {
      let data = {
        id: this.item.id,
      };
      this.disableLoading = true;
      let disableSubCategoryReturn = await disableSubCategory(
        this.categoryId,
        data
      );
      this.disableLoading = false;
      if (disableSubCategoryReturn == "success") {
        this.disableDialog = false;
        this.getSubCategoryX();
      }
    },
    viewSubSubCategories() {},
    viewItems() {},
  },
};
</script>